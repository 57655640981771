import { install } from 'resize-observer';

if (window.ResizeObserver === undefined) {
  install();
}

const setPositionTop = (element: HTMLElement): void => {
  const offsetTop = parseInt(String(element.dataset.anwStickyOffsetTop || 0));
  const findLawyerBanner = document.querySelectorAll('[data-sticky-contact-detail]');
  let topPosition = offsetTop + 'px';

  if (findLawyerBanner.length && findLawyerBanner[0].classList.contains('open')) {
    topPosition = offsetTop + findLawyerBanner[0].clientHeight + 'px';
  }

  element.style.top = topPosition;
};

const initStickyContent = (stickyElement: HTMLElement) => {
  if (!stickyElement.classList.contains('sticky-top')) {
    console.log('sticky element is missing the css class .sticky-top');
  }

  setPositionTop(stickyElement);
  new ResizeObserver(() => setPositionTop(stickyElement)).observe(stickyElement);
  window.addEventListener('resize', () => setPositionTop(stickyElement));
  window.addEventListener('scroll', function () {
    setPositionTop(stickyElement);
  });
};

export default initStickyContent;
